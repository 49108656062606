@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background: rgb(244, 244, 244);
  color: rgb(51, 51, 51);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
}

img {
  margin: 0 auto;
}

.wrapper {
  width: 1920px;
  margin: 0 auto;
}

.title {
  font-size: 1.75rem;
  text-align: center;
  font-weight: 500;
}

.wrapper > .content {
  padding: 6rem 0;
}

.content {
  width: 62.5%;
  text-align: center;
  margin: 0 auto;
}

.footer {
  display: flex;
  flex-direction: column;
  background: #484a50;
  color: #fff;
}

@media (max-width: 1920px) {
  .wrapper {
    width: 100%;
  }

  .content {
    width: 80%;
  }
}

@media (max-width: 640px) {
  .wrapper {
    width: 100%;
  }

  .content {
    width: 80%;
  }
}

